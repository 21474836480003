import React, { useState, useEffect } from 'react';
import { firestore, storage } from './firebase';

const ReplaceManuFile = () => {
  const [id, setId] = useState(''); // The Firestore ID (e.g., 'LUTHGAM-xxxxx')
  const [email, setEmail] = useState('');
  const [firebaseDocId, setFirebaseDocId] = useState(''); // Firebase document ID
  const [selectedFiles, setSelectedFiles] = useState({}); // Selected files to update
  const [filesToUpload, setFilesToUpload] = useState({}); // Files to upload
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [fileUrls, setFileUrls] = useState({}); // Object to hold existing file URLs

  // Fetch existing file URLs from Firestore when authenticated
  useEffect(() => {
    if (isAuthenticated && firebaseDocId) {
      const fetchFileUrls = async () => {
        const doc = await firestore.collection('SubmittedManuscripts').doc(firebaseDocId).get();
        if (doc.exists) {
          setFileUrls(doc.data());
        }
      };
      fetchFileUrls();
    }
  }, [isAuthenticated, firebaseDocId]);

  // Handle authentication
  const handleAuth = async () => {
    try {
      const userRef = await firestore
        .collection('SubmittedManuscripts')
        .where('id', '==', id) // Matching Firestore 'id' field (e.g., 'LUTHGAM-xxxxx')
        .where('email', '==', email)
        .get();

      if (userRef.empty) {
        setUploadMessage('Invalid ID or Email');
        setShowPopup(true); // Show popup with error message
      } else {
        const doc = userRef.docs[0]; // Assuming there is only one match
        setFirebaseDocId(doc.id); // Save the Firebase document ID
        setIsAuthenticated(true);
        setUploadMessage('Authenticated');
        setShowPopup(true); // Show popup with success message
      }
    } catch (error) {
      setUploadMessage('Error during authentication');
      console.error(error);
      setShowPopup(true); // Show popup with error message
    }
  };

  // Handle file submission
  const handleFileUpload = async (e) => {
    e.preventDefault();

    // Prepare an array to hold the upload promises
    const uploadPromises = [];

    for (const fileKey in filesToUpload) {
      const file = filesToUpload[fileKey];
      if (file) {
        try {
          // Upload file to Firebase Storage
          const fileRef = storage.ref().child(`manuscripts/${file.name}`);
          await fileRef.put(file);
          const fileURL = await fileRef.getDownloadURL();

          // Update the specific file URL in Firestore using the Firebase document ID
          uploadPromises.push(
            firestore.collection('SubmittedManuscripts').doc(firebaseDocId).set(
              {
                [fileKey]: fileURL, // Using the selected file key to update the URL
              },
              { merge: true }
            )
          );
        } catch (error) {
          console.error(`Error uploading ${fileKey}:`, error);
          setUploadMessage(`Error uploading ${fileKey}`);
          setShowPopup(true);
        }
      }
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      setUploadMessage('Files uploaded successfully!');
      setShowPopup(true); // Show popup with success message
      // Refresh the page after successful upload
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setUploadMessage('Error updating some files');
      setShowPopup(true); // Show popup with error message
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  // Handle file selection
  const handleFileChange = (fileKey, file) => {
    setFilesToUpload((prev) => ({
      ...prev,
      [fileKey]: file,
    }));
  };

  // Handle checkbox change
  const handleCheckboxChange = (fileKey) => {
    setSelectedFiles((prev) => ({
      ...prev,
      [fileKey]: !prev[fileKey],
    }));
  };

  return (
    <div
      style={{
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        maxWidth: '600px',
        margin: 'auto',
      }}
    >
      <h2>Manuscripts Upload</h2>

      {/* Authentication Form */}
      <div style={{ marginBottom: '20px' }}>
        <label
          style={{ display: 'block', fontSize: '16px', marginBottom: '8px' }}
        >
          ID:
        </label>
        <input
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '14px',
            marginBottom: '15px',
          }}
          type='text'
          value={id}
          onChange={(e) => setId(e.target.value)}
          required
        />
        <label
          style={{ display: 'block', fontSize: '16px', marginBottom: '8px' }}
        >
          Email:
        </label>
        <input
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '14px',
            marginBottom: '15px',
          }}
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007BFF',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={handleAuth}
        >
          Authenticate
        </button>
      </div>

      {/* File Update Form (only shown after authentication) */}
      {isAuthenticated && (
        <form onSubmit={handleFileUpload}>
          <div style={{ marginBottom: '15px' }}>
            <label
              style={{
                display: 'block',
                fontSize: '16px',
                marginBottom: '8px',
              }}
            >
              Select Files to Update:
            </label>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.pictureUrl || false}
                onChange={() => handleCheckboxChange('pictureUrl')}
              />
              <label>Picture</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('pictureUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.firstPageUrl || false}
                onChange={() => handleCheckboxChange('firstPageUrl')}
              />
              <label>First Page</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('firstPageUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.fullPagesUrl || false}
                onChange={() => handleCheckboxChange('fullPagesUrl')}
              />
              <label>Full Pages</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('fullPagesUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.referencesUrl || false}
                onChange={() => handleCheckboxChange('referencesUrl')}
              />
              <label>References</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('referencesUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.employmentLetterUrl || false}
                onChange={() => handleCheckboxChange('employmentLetterUrl')}
              />
              <label>Employment Letter</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('employmentLetterUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.ethicsCertificateUrl || false}
                onChange={() => handleCheckboxChange('ethicsCertificateUrl')}
              />
              <label>Ethics Certificate</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('ethicsCertificateUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.methodologyCertificateUrl || false}
                onChange={() => handleCheckboxChange('methodologyCertificateUrl')}
              />
              <label>Methodology Certificate</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('methodologyCertificateUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.coverLetterUrl || false}
                onChange={() => handleCheckboxChange('coverLetterUrl')}
              />
              <label>Cover Letter</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('coverLetterUrl', e.target.files[0])}
              />
            </div>
            <div>
              <input
                type="checkbox"
                checked={selectedFiles.part1EvidenceUrl || false}
                onChange={() => handleCheckboxChange('part1EvidenceUrl')}
              />
              <label>Part 1 Evidence</label>
              <input
                type="file"
                onChange={(e) => handleFileChange('part1EvidenceUrl', e.target.files[0])}
              />
            </div>
          </div>

          <button
            type="submit"
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              backgroundColor: '#007BFF',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Update Selected Files
          </button>
        </form>
      )}

      {/* Popup for messages */}
      {showPopup && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '20px',
            zIndex: '1000',
          }}
        >
          <p>{uploadMessage}</p>
          <button onClick={handleClosePopup}>Close</button>
        </div>
      )}
    </div>
  );
};

export default ReplaceManuFile;
